* {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  background-color: #C4C4C4;
}

*::-webkit-scrollbar {
  width: 8px;
  background-color: #C4C4C4;
  border-radius: 4px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #02569D;
}

@keyframes changecard {
  0% {
    z-index: 3;
    transform: perspective(100px) translate3d(-160px, 0, 20px);
  }

  30% {
    z-index: 3;
    transform: perspective(100px) translate3d(-220px, 0, 20px);
  }

  80% {
    z-index: 0;
    transform: perspective(100px) translate3d(-220px, 0, 0px);
  }

  100% {
    z-index: 0;
    transform: perspective(100px) translate3d(-0px, 0, 0px);
  }
}